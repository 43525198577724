<template>
  <div class="index-form">
    <div class="index-form__container">
      <div class="index-form__heart-img">
        <img
          loading="lazy"
          src="/v2/main/form/heart.svg"
          alt="heart"
          class="no-tablet"
        >
        <img
          loading="lazy"
          src="/v2/main/form/heart-mobile.svg"
          alt="heart"
          class="tablet"
        >
      </div>

      <img
        loading="lazy"
        src="/v2/main/form/arrow.svg"
        alt="heart"
        class="index-form__arrow-img"
      >

      <h2 class="title-tertiary">
        Нужна консультация? <br> Оставьте заявку в отделе заботы
      </h2>

      <div class="index-form__form">
        <p>Мы&nbsp;позвоним с&nbsp;8:00-20:00 и&nbsp;ответим на&nbsp;любые ваши вопросы</p>

        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="Имя"
        />

        <form-phone-field
          ref="phoneField"
          :placeholder="cenyPage ? 'Телефон родителя' : 'Телефон'"
          class="form__input form__input_phone"
          :errors-list="submitError"
          :error="validForm === false"
          @updatePhone="phoneInput= $event"
        />

        <form-phone-field
          v-if="cenyPage"
          ref="phoneCommentField"
          placeholder="Телефон ученика"
          class="form__input form__input_phone"
          :errors-list="submitError"
          :error="validForm === false"
          @updatePhone="phoneCommentInput = $event"
          @clearSubmitError="clearSubmitError"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input form__input_email"
          :submit-error="submitError"
          placeholder="Почта"
          @clearSubmitError="clearSubmitError"
        />

        <form-text-field
          v-if="partnersSchool"
          v-model="comment"
          class="form__input"
          placeholder="Промокод"
        />

        <div class="index-form__form_submit m-btn__pull">
          <button
            class="m-btn"
            @click="submit"
          >
            Отправить
          </button>
        </div>

        <span class="index-form__form_privacy text-tertiary">
          Нажимая на кнопку, вы соглашаетесь
          <button
            v-if="forCom"
            class="m-btn"
            @click="$root.$emit('comPrivacy')"
          >
            с условиями обработки данных
          </button>
          <nuxt-link
            v-else
            to="/privacy"
            target="_blank"
          >
            с условиями обработки данных
          </nuxt-link>
        </span>
      </div>

      <div
        v-if="!cenyPage"
        class="index-form__soc"
      >
        <span>Или напишите нам в чат:</span>
        <a
          href="https://wa.me/79686286327"
          target="_blank"
          class="index-form__whatsapp"
        >
          <img
            loading="lazy"
            src="/v2/main/form/whatsapp.svg"
            alt="whatsapp"
          >
        </a>
        <a
          :href="$getTelegramLink($route)"
          target="_blank"
          class="index-form__telegram"
        >
          <img
            loading="lazy"
            src="/v2/main/form/tg.svg"
            alt="telegram"
          >
        </a>
        <a
          href="mailto:nstolbova@synergy.ru"
          target="_blank"
          class="index-form__mail"
        >
          <img
            loading="lazy"
            src="/v2/main/form/mail.svg"
            alt="mail"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import FormTextField from './fileds/FormTextField.vue';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
  needToSendComments: true,
});

export default {
  name: 'FormIndex',
  components: {
    FormTextField,
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],
  props: {
    landCode: {
      type: String,
      default: null,
    },
    forCom: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    comment: '',
  }),

  computed: {
    commentsToSend() {
      return this.comment ? `промокод: ${this.comment}` : null;
    },
    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
    partnersSchool() {
      return this.$route.name === 'online-school-partners';
    },
  },

  created() {
    if (this.cenyPage) {
      this.phoneCommentInput = '';
      this.needCommentPhone = true;
    }
  },
};
</script>

<style scoped lang="scss">
.index-form {
  background-color: $gray-bg;
  overflow: hidden;
  padding-bottom: 4px;

  .index-form__container {
    position: relative;

    border-radius: 20px;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 0 $black;

    background-color: $white-color;
    background-image: url('/v2/main/form/bg.webp');
    background-position: right bottom -1px;
    background-size: 815px auto;
    background-repeat: no-repeat;

    padding: 38px;
    width: min(1320px, calc(100% - 16px));
    margin-left: auto;
    margin-right: auto;

    @include media-down(desktop) {
      background-size: 700px auto;
    }

    @include media-down(laptop) {
      background-size: 450px auto;
      padding: 18px;
    }

    @include media-down(tablet) {
      background-size: 450px auto;
      background-position: center bottom;
      padding: 8px 8px 306px;
      box-shadow: 2px 4px 0 0 $black;
    }
  }

  .index-form__heart-img {
    position: absolute;
    top: 157px;
    right: 770px;

    @include media-down(laptop) {
      right: auto;
      left: 400px;
      top: 280px;
    }

    @include media-down(laptop) {
      left: 24px;
      top: auto;
      bottom: 248px;
    }
  }

  .index-form__arrow-img {
    position: absolute;
    top: 50px;
    left: 630px;

    @include media-down(desktop) {
      transform: scale(0.7);
      top: 75px;
      left: 600px;
    }

    @include media-down(laptop) {
      transform: rotate(45deg);
      top: 150px;
    }

    @include media-down(tablet) {
      top: auto;
      left: calc(50% + 60px);
      bottom: 280px;
      transform: rotate(65deg) scale(0.7);
    }
  }

  .title-tertiary {
    letter-spacing: -0.8px;
    position: relative;

    @include media-down(tablet) {
      text-align: center;
      font-size: 30px;
      letter-spacing: 0;
    }
  }

  .index-form__form {
    max-width: 400px;
    margin-top: 20px;
    position: relative;

    @include media-down(laptop) {
      max-width: 350px;
    }

    @include media-down(tablet) {
      margin-top: 10px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 30px;

      @include media-down(tablet) {
        text-align: center;
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 600;
        line-height: 100%;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .form__input {
      margin-bottom: 10px;

      @include media-down(mobile) {
        margin-bottom: 8px;
      }

      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        color: $black;
        font-size: 20px;
        letter-spacing: 0.4px;
        border-radius: 15px;
        border: 2px solid $black;
        padding: 20px 18px 22px;
        height: auto;

        @include media-down(mobile) {
          padding: 16px 14px 17px;
          font-size: 18px;
          letter-spacing: 0.36px;
        }
      }
    }

    .index-form__form_submit {
      margin-top: 20px;
      margin-bottom: 10px;
      border-radius: 15px;

      @include media-down(mobile) {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      button {
        padding: 19px 20px 21px;
        border-radius: 15px;
        border: 2px solid $black;
        background-color: #b6b6ff;
        color: $black;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        cursor: pointer;
        position: relative;

        @include media-down(mobile) {
          padding: 15px 10px 16px;
          font-size: 18px;
        }

        &:after {
          content: '';
          width: 7px;
          height: 21px;
          display: inline-block;
          background-image: url('/v2/main/form/button-arrow.svg');
          margin-left: 5px;
        }
      }
    }

    .index-form__form_privacy {
      font-variant-numeric: lining-nums proportional-nums;
      margin-bottom: 2px;
      display: block;

      @include media-down(tablet) {
        text-align: center;
        margin-bottom: 36px;
        font-weight: 500;
      }

      button,
      a {
        display: block;
        font-size: 18px;
        color: $blue-dark;
      }
    }
  }

  .index-form__soc {
    display: flex;
    align-items: center;
    gap: 10px;
    right: 40px;
    top: 40px;
    position: absolute;

    @include media-down(laptop) {
      position: static;
    }

    @include media-down(tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }

    span {
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      padding-right: 10px;

      @include media-down(tablet) {
        width: 100%;
        text-align: center;
        padding-right: 0;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 100%;
      }
    }

    a {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      border: 2px solid $black;
      box-shadow: 2px 2px 0 0 $black;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 200ms;

      &:hover {
        box-shadow: none;
        transform: translate(2px, 2px);
      }

      &.index-form__whatsapp {
        background-color: #40c351;
      }

      &.index-form__telegram {
        background-color: #2e96ca;
      }

      &.index-form__mail {
        background-color: #1977f3;
      }
    }
  }
}
</style>
